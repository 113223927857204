import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getParamlistGenericService = (service) => {
  const URL = `/paramlists/${service}/`

  return {
    URL,
    LIST: authValidationMiddleware(async (params = {}) => {
      const response = await axiosApiInstance.get(URL, { params })
      return response.data
    }),
    GET: authValidationMiddleware(async (id, params = {}) => {
      const response = await axiosApiInstance.get(`${URL}${id}/`, { params })
      return response.data
    }),
    POST: authValidationMiddleware(async (payload) => {
      const response = await axiosApiInstance.post(URL, payload)
      return response.data
    }),
    PUT: authValidationMiddleware(async (id, payload) => {
      const response = await axiosApiInstance.put(`${URL}${id}/`, payload)
      return response.data
    }),

    DELETE: authValidationMiddleware(async (id) => {
      const response = await axiosApiInstance.delete(`${URL}${id}/`)
      return response.data
    })
  }
}

export const getObjectiveRisks = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get("/paramlists/riesgo_objetivo/", { params })

  return response.data
})

export const createObjectiveRisk = authValidationMiddleware(async (name, empresa = null) => {
  const response = await axiosApiInstance.post("/paramlists/riesgo_objetivo/", { nombre: name, empresa })

  return response.data
})

export const deleteObjectiveRisk = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/riesgo_objetivo/${id}/`)

  return response.data
})

export const updateObjectiveRisk = authValidationMiddleware(async ({ id, values }) => {
  const response = await axiosApiInstance.put(`/paramlists/riesgo_objetivo/${id}/`, values)

  return response.data
})

export const createSpecificRisk = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.post(`/paramlists/riesgo_especifico/${id}/`, {
    nombre: name
  })

  return response.data
})
export const updateSpecificRisk = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/riesgo_general/${id}/`, { nombre: name })

  return response.data
})

export const deleteSpecificRisk = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/riesgo_general/${id}/`)

  return response.data
})

export const getFactorRisks = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get("/paramlists/riesgo_factor/", { params })

  return response.data
})

export const createFactorRisk = authValidationMiddleware(async (name, empresa = null) => {
  const response = await axiosApiInstance.post("/paramlists/riesgo_factor/", {
    nombre: name,
    empresa
  })

  return response.data
})

export const deleteFactorRisk = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/riesgo_factor/${id}/`)

  return response.data
})

export const updateFactorRisk = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/riesgo_factor/${id}/`, {
    nombre: name
  })

  return response.data
})

export const getGeneralRisks = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get("/paramlists/riesgo_general/", { params })

  return response.data
})

export const createGeneralRisk = authValidationMiddleware(async (name, empresa = null) => {
  const response = await axiosApiInstance.post("/paramlists/riesgo_general/", {
    nombre: name,
    empresa
  })

  return response.data
})

export const deleteGeneralRisk = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/riesgo_general/${id}/`)

  return response.data
})

export const updateGeneralRisk = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/riesgo_general/${id}/`, {
    nombre: name
  })

  return response.data
})

export const getControlObjectives = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get("/paramlists/control_objetivo/", { params })

  return response.data
})

export const getTypeOfFinding = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get("/paramlists/hallazgo_tipo/")

  return response.data
})

export const actionsAuditTypes = () => {
  const endPoint = "/paramlists/auditoria_tipo/"

  return {
    GET: authValidationMiddleware(async (empresa) => {
      const params = {}
      if (empresa) {
        params.empresa = empresa
      }
      const response = await axiosApiInstance.get(endPoint, { params })

      return response.data
    }),

    CREATE: authValidationMiddleware(async (nombre, empresa) => {
      const response = await axiosApiInstance.post(endPoint, {
        nombre,
        empresa
      })

      return response.data
    }),

    UPDATE: authValidationMiddleware(async ({ id, name }) => {
      const response = await axiosApiInstance.put(`${endPoint}${id}/`, {
        nombre: name
      })

      return response.data
    }),

    DELETE: authValidationMiddleware(async (id) => {
      const response = await axiosApiInstance.delete(`${endPoint}${id}/`)

      return response.data
    })
  }
}

export const createTypeOfFinding = authValidationMiddleware(async (name) => {
  const response = await axiosApiInstance.post("/paramlists/hallazgo_tipo/", {
    nombre: name
  })

  return response.data
})

export const updateTypeOfFinding = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/hallazgo_tipo/${id}/`, {
    nombre: name
  })

  return response.data
})

export const deleteTypeOfFinding = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/hallazgo_tipo/${id}/`)

  return response.data
})

export const getResidualRiskTreatment = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get("/paramlists/riesgo_residual_tratamiento/")

  return response.data
})

export const createResidualRiskTreatment = authValidationMiddleware(async (name) => {
  const response = await axiosApiInstance.post("/paramlists/riesgo_residual_tratamiento/", {
    nombre: name
  })

  return response.data
})

export const getControlCriteria = authValidationMiddleware(async (params = null) => {
  const requestConfig =
    typeof params === "undefined" || params === null || Object.keys(params).length === 0 ? undefined : { params }
  const response = await axiosApiInstance.get("/paramlists/control_criterio/", requestConfig)

  return response.data
})

export const updateControlCriteria = authValidationMiddleware(async ({ criteriaId = "", data = null }) => {
  const requestConfig =
    typeof data === "undefined" || data === null || Object.keys(data).length === 0 ? undefined : { ...data }

  const response = await axiosApiInstance.put(`/paramlists/control_criterio/${criteriaId}/`, requestConfig)

  return response.data
})

export const deleteControlCriteria = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/control_criterio/${id}/`)

  return response.data
})

export const getObjectiveControl = authValidationMiddleware(async (empresa = null) => {
  const url = `/paramlists/control_objetivo/${empresa ? `?empresa=${empresa}` : ""}`
  const response = await axiosApiInstance.get(url)

  return response.data
})

export const createObjectiveControl = authValidationMiddleware(async (name, empresa = null) => {
  const response = await axiosApiInstance.post("/paramlists/control_objetivo/", {
    nombre: name,
    empresa,
    sigla: name.substr(0, 1).toUpperCase()
  })

  return response.data
})

export const updateObjectiveControl = authValidationMiddleware(async ({ id, values }) => {
  const response = await axiosApiInstance.put(`/paramlists/control_objetivo/${id}/`, values)

  return response.data
})

export const deleteObjectiveControl = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/control_objetivo/${id}/`)

  return response.data
})

export const processTypeList = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get("/paramlists/proceso_tipo/", { params })

  return response.data
})

export const createProcessType = authValidationMiddleware(async (name, empresa) => {
  const response = await axiosApiInstance.post("/paramlists/proceso_tipo/", {
    nombre: name,
    empresa
  })

  return response.data
})

export const updateProcessType = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/proceso_tipo/${id}/`, { nombre: name })

  return response.data
})

export const deleteProcessType = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/proceso_tipo/${id}/`)

  return response.data
})

export const getCompanies = authValidationMiddleware(async (list_mode = null) => {
  const params = {}

  list_mode && (params.list_mode = list_mode)

  const response = await axiosApiInstance.get("/paramlists/cliente_empresa/", { params })

  return response.data
})

export const createCompany = authValidationMiddleware(async (name) => {
  const response = await axiosApiInstance.post("/paramlists/cliente_empresa/", {
    nombre: name
  })

  return response.data
})

export const deleteCompany = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/cliente_empresa/${id}/`)

  return response.data
})

export const updateCompany = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/cliente_empresa/${id}/`, { nombre: name })

  return response.data
})

export const getCompanyGenericService = (id, service) => {
  const URL = `/paramlists/cliente_empresa/${id}/${service}/`

  return {
    URL,
    GET: authValidationMiddleware(async (params = {}) => {
      const response = await axiosApiInstance.get(URL, { params })
      return response.data
    }),
    POST: authValidationMiddleware(async (payload) => {
      const response = await axiosApiInstance.post(URL, payload)
      return response.data
    }),
    PUT: authValidationMiddleware(async (payload) => {
      const response = await axiosApiInstance.put(URL, payload)
      return response.data
    })
  }
}

export const getUnitBusiness = authValidationMiddleware(async ({ empresa } = {}) => {
  const params = {}
  if (empresa) {
    params["empresa"] = empresa
  }

  const response = await axiosApiInstance.get("/paramlists/cliente_unidadnegocio/", { params })

  return response.data
})

export const createUnitBusiness = authValidationMiddleware(async (name, empresaId) => {
  const response = await axiosApiInstance.post("/paramlists/cliente_unidadnegocio/", {
    nombre: name,
    empresa: empresaId
  })

  return response.data
})

export const updateUnitBusiness = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/cliente_unidadnegocio/${id}/`, { nombre: name })

  return response.data
})

export const deleteUnitBusiness = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/cliente_unidadnegocio/${id}/`)

  return response.data
})

export const createBusinessUnitByCompany = authValidationMiddleware(async ({ name, companyId }) => {
  const response = await axiosApiInstance.post("/paramlists/cliente_unidadnegocio/", {
    nombre: name,
    empresa: companyId
  })

  return response.data
})

export const createControlCriteria = authValidationMiddleware(async ({ name = "", position = -1, empresa = null }) => {
  const response = await axiosApiInstance.post(`/paramlists/control_criterio/`, {
    nombre: name,
    position,
    empresa
  })

  return response.data
})

export const createControlCriteriaValue = authValidationMiddleware(
  async ({ name = "", value = "", position = -1, criteriaId = "" }) => {
    const response = await axiosApiInstance.post(`/paramlists/control_criterio_valor/`, {
      nombre: name,
      valor: value,
      position,
      id_criterio: criteriaId
    })

    return response.data
  }
)

export const updateControlCriteriaList = authValidationMiddleware(async (list) => {
  const response = await axiosApiInstance.put(`/paramlists/control_criterio_valor/criterios_list/`, {
    criterios_list: list
  })
  return response.data
})

export const createParamlistGlobalGeneric = authValidationMiddleware(async (nameParamlist = null, empresa = null) => {
  const response = await axiosApiInstance.post(`/paramlists/${nameParamlist}/copy_from_global/`, {
    empresa
  })

  return response.data
})

export const deleteParamlistGlobalGeneric = authValidationMiddleware(async (nameParamlist = null, empresa = null) => {
  const response = await axiosApiInstance.delete(`/paramlists/${nameParamlist}/reset_to_global/?empresa=${empresa}`)

  return response.data
})

export const updateControlCriteriaValue = authValidationMiddleware(
  async ({ id = "", name = "", value = "", position = -1 }) => {
    const response = await axiosApiInstance.put(`/paramlists/control_criterio_valor/${id}/`, {
      nombre: name,
      valor: value,
      position
    })

    return response.data
  }
)

export const updateControlMultipleCriteriaValue = authValidationMiddleware(async (criterios) => {
  const response = await axiosApiInstance.post(`/paramlists/control_criterio_valor/create_criterios_list/`, {
    criterios_list: criterios
  })
  return response.data
})

export const deleteControlCriteriaValue = authValidationMiddleware(async ({ id = "" }) => {
  const response = await axiosApiInstance.delete(`/paramlists/control_criterio_valor/${id}/`)

  return response.data
})

export const getEvaluationRisk = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/`, { params })

  return response.data
})

export const getActUnproductive = authValidationMiddleware(async (params = {}) => {
  const url = `/paramlists/act_improductiva/`
  const response = await axiosApiInstance.get(url, { params })
  return response.data
})

export const createActUnproductive = authValidationMiddleware(async (payload, empresa = null) => {
  const response = await axiosApiInstance.post(`/paramlists/act_improductiva/`, {
    ...payload,
    empresa
  })

  return response.data
})

export const updateActUnproductive = authValidationMiddleware(async (id, payload) => {
  const response = await axiosApiInstance.put(`/paramlists/act_improductiva/${id}/`, payload)

  return response.data
})

export const deleteActUnproductive = authValidationMiddleware(async ({ id }) => {
  const response = await axiosApiInstance.delete(`/paramlists/act_improductiva/${id}/`)

  return response.data
})

export const getPriorizacionCriterios = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get("/paramlists/auditoria_priorizacion_criterio/", { params })
  return response.data
})

export const getAuthorizationCriteriaAudit = authValidationMiddleware(async ({ empresa = null }) => {
  let url
  if (empresa) {
    url = `/paramlists/auditoria_priorizacion_criterio/?empresa=${empresa}`
  } else {
    url = `/paramlists/auditoria_priorizacion_criterio/`
  }
  const response = await axiosApiInstance.get(url)

  return response.data
})

export const updateControlCriteriaAudit = authValidationMiddleware(
  async ({ criteriaId = "", data = null, empresa = null }) => {
    const requestConfig =
      typeof data === "undefined" || data === null || Object.keys(data).length === 0 ? undefined : { ...data }

    const response = await axiosApiInstance.put(
      `/paramlists/auditoria_priorizacion_criterio/${criteriaId}/`,
      //empresa,
      requestConfig
    )

    return response.data
  }
)

export const deleteControlCriteriaAudit = authValidationMiddleware(async ({ id = "" }) => {
  const response = await axiosApiInstance.delete(`/paramlists/auditoria_priorizacion_criterio/${id}/`)

  return response.data
})

export const updateNameControlCriteriaAudit = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/auditoria_priorizacion_criterio/${id}/`, {
    nombre: name
  })

  return response.data
})

export const createControlCriteriaAudit = authValidationMiddleware(
  async ({ name = "", position = -1, empresa = null }) => {
    const response = await axiosApiInstance.post(`/paramlists/auditoria_priorizacion_criterio/`, {
      nombre: name,
      position,
      empresa
    })

    return response.data
  }
)

export const createControlCriteriaAuditGlobal = authValidationMiddleware(async ({ empresa = null }) => {
  const response = await axiosApiInstance.post(`/paramlists/auditoria_priorizacion_criterio/copy_from_global/`, {
    empresa
  })

  return response.data
})

export const deleteControlCriteriaAuditGlobal = authValidationMiddleware(async ({ empresa = null }) => {
  const response = await axiosApiInstance.delete(
    `/paramlists/auditoria_priorizacion_criterio/reset_to_global/?empresa=${empresa}`
  )
  return response.data
})

export const createControlCriteriaValueAudit = authValidationMiddleware(
  async ({ name = "", value = "", position = -1, criteriaId = "" }) => {
    const response = await axiosApiInstance.post(`/paramlists/auditoria_priorizacion_criterio_valor/`, {
      nombre: name,
      valor: value,
      position,
      criterio: criteriaId
    })

    return response.data
  }
)

export const updateControlCriteriaValueAudit = authValidationMiddleware(async (id, params) => {
  const response = await axiosApiInstance.put(`/paramlists/auditoria_priorizacion_criterio_valor/${id}/`, params)

  return response.data
})

export const deleteControlCriteriaValueAudit = authValidationMiddleware(async ({ id = "" }) => {
  const response = await axiosApiInstance.delete(`/paramlists/auditoria_priorizacion_criterio_valor/${id}/`)

  return response.data
})
